import type { CaseStudyContent } from "~/types/caseStudy";
import { LogoWithDomainTags } from "../LogoWithDomainTags";
import "./styles.css";

export enum CaseStudyComponent {
  OurWorkWideCard = "our-work-wide-card",
  OurWorkSquareCard = "our-work-square-card",
  HomeSliderCard = "home-slider-card",
}

interface CaseStudyCardProps extends CaseStudyContent {
  component: CaseStudyComponent;
}

const CaseStudyCard = ({
  companyName,
  heading,
  logo,
  domainTags,
  component,
  topImage,
  slug,
}: CaseStudyCardProps) => {
  const wrapperClass = `${component as string} flex flex-col image-animated`;

  return (
    <a className={wrapperClass} href={`/case-studies/${slug}`}>
      <div className="image-container mobile-padding-ratio-square relative w-full overflow-hidden rounded-lg bg-gray-800">
        <img
          src={topImage.metadata.src}
          alt={topImage.alt}
          className="image-animated__img absolute left-0 top-0 size-full object-cover"
          loading="lazy"
        />
      </div>
      <div className="content">
        <LogoWithDomainTags logo={logo} alt={companyName} tags={domainTags} />
        <p className="heading-5-mobile pr-20 pt-4 max-md:text-body-mobile max-md:pr-8">{heading}</p>
      </div>
    </a>
  );
};

export { CaseStudyCard };
